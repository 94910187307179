.game-img{
	margin-top: 14px;
    width: 100px;
    height: 100px;
    border-radius: 50%;
    margin-bottom: 14px;
    filter: drop-shadow(1px 2px 3px black);
}

::-webkit-scrollbar-track
{
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
	border-radius: 10px;
	background-color: #F5F5F5;
}

::-webkit-scrollbar
{
	width: 9px;
	background-color: #F5F5F5;
}

::-webkit-scrollbar-thumb
{
	border-radius: 10px;
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
	background-color: #11cdef;;
}

#headerPopup{
	width:75%;
	margin:0 auto;
  }
  
#headerPopup iframe{
	width:100%;
	margin:0 auto;
}

.video-holder-div{
    position: fixed;
    width: 500%;
    height: 500%;
    left: -800px;
    top: -600px;
    background: rgba(0,0,0,0.7);
    z-index: 10;
}

.video{
    position: fixed;
    width: 100%;
    height: 100%;
    left: 0;
    top: 150px;
    z-index: 10;
}

.z-inded-1m{
	z-index: -1;
}

.header-remover{
	transform: translateY(-100%) !important
}

.stop-scrolling {
	height: 100%;
	overflow: hidden;
  }

  .slider {
	position: absolute;
	top: 600px;
	bottom: 0;
	right: 0;
	left: 0;
	width: 200px;
	height: 200px;
 	margin: auto;
	-webkit-perspective: 600px;
	        perspective: 600px;
}

	.slider * {
		-webkit-transition: all 1s cubic-bezier(0.5, -0.75, 0.2, 1.5);
		        transition: all 1s cubic-bezier(0.5, -0.75, 0.2, 1.5);
	}

	.container-cube {
		width: inherit;
		height: inherit;
		-webkit-transform-style: preserve-3d;
		        transform-style: preserve-3d;
		-webkit-transform: rotateY(0deg) rotateX(0deg);
		        transform: rotateY(0deg) rotateX(0deg);
	}

		.slide, .slide:after, .slide:before {
			display: block;
			width: inherit;
			height: inherit;
			background: url('https://cdn.dribbble.com/users/5031/screenshots/7008431/dribbble.png');
			position: absolute;
			-webkit-transform-style: preserve-3d;
			        transform-style: preserve-3d;
			background-size: cover;
			background-position: center;
		}

		.slide.x {
		  -webkit-transform: rotateY(90deg);
		          transform: rotateY(90deg);
		} 

			.slide.x:after {
				content: '';
				background-image: url('https://firebasestorage.googleapis.com/v0/b/benedek-szabolcs-27729.appspot.com/o/js.webp?alt=media&token=80216eba-6d12-40cc-8a74-8bc699828f50');
				-webkit-transform: translateZ(100px) rotateZ(-90deg);
				        transform: translateZ(100px) rotateZ(-90deg);
			}

			.slide.x:before {
				content: '';
				background-image: url('https://firebasestorage.googleapis.com/v0/b/benedek-szabolcs-27729.appspot.com/o/firebase.jpg?alt=media&token=54aabeb2-e0a5-4f6e-b0bc-b63ab48880a7');
				-webkit-transform: translateZ(-100px) rotateZ(270deg);
				        transform: translateZ(-100px) rotateZ(270deg);
			}

		.slide.y {
		  -webkit-transform: rotateX(90deg);
		          transform: rotateX(90deg);
		} 

		.slide.y:after {
			content: '';
			background-image: url('https://firebasestorage.googleapis.com/v0/b/benedek-szabolcs-27729.appspot.com/o/css.jpg?alt=media&token=a374d4a5-1a4b-4945-b25e-9b6a03fe5140');
			-webkit-transform: translateZ(100px);
					transform: translateZ(100px);
		}

		.slide.y:before {
			content: '';
			background-image: url('https://firebasestorage.googleapis.com/v0/b/benedek-szabolcs-27729.appspot.com/o/vue.png?alt=media&token=46c55149-4c6c-475c-a77a-b059b1e26858');
			-webkit-transform: translateZ(-100px);
					transform: translateZ(-100px);
		}

		.slide.z {
		  -webkit-transform: rotateX(0);
		          transform: rotateX(0);
		} 

			.slide.z:after {
				content: '';
				background-image: url('https://firebasestorage.googleapis.com/v0/b/benedek-szabolcs-27729.appspot.com/o/unity.jpg?alt=media&token=14dc9ac4-b0c3-4666-a520-f78ce900d4ec');
				-webkit-transform: translateZ(100px);
				        transform: translateZ(100px);
			}

			.slide.z:before {
				content: '';
				background-image: url('https://firebasestorage.googleapis.com/v0/b/benedek-szabolcs-27729.appspot.com/o/ts.png?alt=media&token=8a7abc38-beaa-4a92-abca-fbd45d51c52f');
				-webkit-transform: translateZ(-100px) rotateY(180deg);
				        transform: translateZ(-100px) rotateY(180deg);
			}


		.container-cube {
			-webkit-animation: rotate 15s infinite cubic-bezier(1, -0.75, 0.5, 1.2);
			animation: rotate 15s infinite cubic-bezier(1, -0.75, 0.5, 1.2);
		}

		@-webkit-keyframes rotate {
			0%, 10% {-webkit-transform: rotateY(0deg) rotateX(0deg);transform: rotateY(0deg) rotateX(0deg);}
			15%, 20% {-webkit-transform: rotateY(180deg) rotateX(0deg);transform: rotateY(180deg) rotateX(0deg);}
			25%, 35% {-webkit-transform: rotateY(180deg) rotateX(270deg);transform: rotateY(180deg) rotateX(270deg);}
			40%, 50% {-webkit-transform: rotateY(180deg) rotateX(90deg);transform: rotateY(180deg) rotateX(90deg);}
			55%, 65% {-webkit-transform: rotateY(-90deg) rotateX(90deg);transform: rotateY(-90deg) rotateX(90deg);}
			70%, 80% {-webkit-transform: rotateY(90deg) rotateX(90deg);transform: rotateY(90deg) rotateX(90deg);}
			90%, 95% {-webkit-transform: rotateY(0deg) rotateX(90deg);transform: rotateY(0deg) rotateX(90deg);}
		}

		@keyframes rotate {
			0%, 10% {-webkit-transform: rotateY(0deg) rotateX(0deg);transform: rotateY(0deg) rotateX(0deg);}
			15%, 20% {-webkit-transform: rotateY(180deg) rotateX(0deg);transform: rotateY(180deg) rotateX(0deg);}
			25%, 35% {-webkit-transform: rotateY(180deg) rotateX(270deg);transform: rotateY(180deg) rotateX(270deg);}
			40%, 50% {-webkit-transform: rotateY(180deg) rotateX(90deg);transform: rotateY(180deg) rotateX(90deg);}
			55%, 65% {-webkit-transform: rotateY(-90deg) rotateX(90deg);transform: rotateY(-90deg) rotateX(90deg);}
			70%, 80% {-webkit-transform: rotateY(90deg) rotateX(90deg);transform: rotateY(90deg) rotateX(90deg);}
			90%, 95% {-webkit-transform: rotateY(0deg) rotateX(90deg);transform: rotateY(0deg) rotateX(90deg);}
		}


.cube-shadow {
	display: block;
	width: 200px;
	height: 200px;
	background: rgba(0,0,0,0.75);
	position: absolute;
	top: 120%;
	-webkit-transform: rotateX(90deg);
	        transform: rotateX(90deg);
	z-index: -1;
	-webkit-filter: blur(20px);
	filter: url('data:image/svg+xml;charset=utf-8,<svg xmlns="http://www.w3.org/2000/svg"><filter id="filter"><feGaussianBlur stdDeviation="20" /></filter></svg>#filter');
	-webkit-filter: blur(20px);
	        filter: blur(20px);
	left: 0;
	right: 0;
	margin: auto;
	-webkit-animation: rotateShadow 15s infinite cubic-bezier(1, -0.75, 0.5, 1.2);
	animation: rotateShadow 15s infinite cubic-bezier(1, -0.75, 0.5, 1.2);
}

.cube-margins{
    margin-bottom: 41rem !important;
}

.cube-header-aligner {
    position: relative;
    margin-top: 13%;
}

@keyframes rotateShadow {
	0%, 10% {-webkit-transform: rotateY(0deg) rotateX(90deg);}	
	15%, 20% {-webkit-transform: rotateY(180deg) rotateX(90deg);}
	20.1%, 20.9% {-webkit-transform: rotateY(180deg) rotateX(90deg) translatez(5px);}
	25%, 35% {-webkit-transform: rotateY(180deg) rotateX(90deg);}
	35.1%, 35.9% {-webkit-transform: rotateY(180deg) rotateX(90deg) translatez(-5px);}
	40%, 50% {-webkit-transform: rotateY(180deg) rotateX(90deg);}
	55%, 65% {-webkit-transform: rotateY(0deg) rotateX(90deg);}
	70%, 80% {-webkit-transform: rotateY(180deg) rotateX(90deg);}
	90%, 99% {-webkit-transform: rotateY(90deg) rotateX(90deg);}
	99.1%, 99.9% {-webkit-transform: rotateY(90deg) rotateX(90deg) translatez(-5px);}
}

